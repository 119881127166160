<template>
    <div class="CarsTable">
        <BaseDashboardTable
            ref="dashboardTable"
            :columns="columns"
            :items="formattedCars"
            :sort="sortState"
            :empty-message="'Keine Zuweisungen vorhanden'"
            :use-hierarchical-rows="true"
            @sort-changed="handleSortChanged"
            @row-click="handleCarClick"
            @toggle-expand="handleToggleExpand"
            @row-hover="args => $emit('onRowHover', args)"
            @row-leave="args => $emit('onRowLeave', args)"
        >
            <template #title>
                <h3>Fahrzeuge</h3>
            </template>

            <!-- Custom column for license number -->
            <template #column-licenseNumber="{ item }">
                <span :class="{ 'child-license': item._isChild }">{{ item.licenseNumber }}</span>
            </template>

            <!-- Custom column for installed since -->
            <template #column-installedSince="{ item }">
                <div class="date-range">
                    <div v-if="item._isChild" class="date-row">
                        <span class="date-label">Von: </span>
                        <span>{{ formatDate(item.installation?.installedAt) }}</span>
                        <span class="date-separator">|</span>
                        <span class="date-label">Bis: </span>
                        <span>{{ formatDate(item.installation?.removedAt) }}</span>
                    </div>
                    <span v-else>{{ item.installedSince }}</span>
                </div>
            </template>

            <!-- Custom column for device type -->
            <template #column-deviceType="{ item }">
                <div class="device-info">
                    <div v-if="item._isChild" class="device-row">
                        <span>{{ item.deviceType }}</span>
                        <span v-if="item.deviceId" class="device-separator">|</span>
                        <span v-if="item.deviceId" class="device-id">ID: {{ item.deviceId }}</span>
                    </div>
                    <span v-else>{{ item.deviceType }}</span>
                </div>
            </template>

            <!-- Custom column for information button -->
            <template #column-information="{ item }">
                <Button
                    v-if="!item._isChild"
                    variant="table"
                    size="small"
                    @onClick="$emit('onCarInformationClick', { car: item.originalCar })"
                >
                    Informationen
                </Button>
        </template>

            <!-- We don't need these with the hierarchical approach -->
            <!-- <template #column-expandable="{ item }">
                <div
                    class="expand-button"
                    v-if="hasInstallations(item)"
                    @click.stop="handleExpandClick(item)"
                    :class="{ 'is-expanded': item._expanded }"
                >
                    <i
                        :class="[item._expanded ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line']"
                    ></i>
                </div>
                <div v-else class="expand-button-placeholder"></div>
            </template> -->

            <!-- Also no longer needed with hierarchical rows -->
            <!-- <template #nested-table="{ item }">
                <div class="installation-history">
                    <h4 class="installation-history-title">Installationshistorie</h4>
                    <table class="installation-table">
                        <thead>
                            <tr>
                                <th>Von</th>
                                <th>Bis</th>
                                <th>Geräte Typ</th>
                                <th>Geräte ID</th>
                                <th>Kennzeichen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(installation, idx) in item.originalCar.installations"
                                :key="idx"
                            >
                                <td>{{ formatDate(installation?.installedAt) }}</td>
                                <td>{{ formatDate(installation?.removedAt) }}</td>
                                <td>{{ installation?.device?.type || '' }}</td>
                                <td>{{ installation?.device?.manufacturerNumber || '' }}</td>
                                <td>{{ installation?.licenseNumber || '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template> -->
        </BaseDashboardTable>
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { dateToString } from '@/lib/helper';
import BaseDashboardTable from '@/components/BaseDashboardTable';
import Button from '@/components/widgets/Button';

export default {
    name: 'CarsTable',
    components: {
        BaseDashboardTable,
        Button,
    },
    props: {
        cars: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            sortState: {
                column: 'licenseNumber',
                ascending: true,
            },
            columns: [
                {
                    key: 'licenseNumber',
                    label: 'Kennzeichen',
                    width: '18%',
                    minWidth: '120px',
                },
                {
                    key: 'make',
                    label: 'Hersteller',
                    width: '14%',
                    minWidth: '100px',
                },
                {
                    key: 'model',
                    label: 'Model',
                    width: '14%',
                    minWidth: '100px',
                },
                {
                    key: 'installedSince',
                    label: 'Verbaut Seit',
                    width: '22%',
                    minWidth: '150px',
                },
                {
                    key: 'deviceType',
                    label: 'Geräte Typ/ID',
                    width: '18%',
                    minWidth: '120px',
                },
                {
                    key: 'information',
                    label: 'Information',
                    width: '14%',
                    minWidth: '100px',
                    sortable: false,
                    align: 'center',
                },
            ],
            expandedCarIds: [],
        };
    },
    computed: {
        formattedCars() {
            if (!this.cars || this.cars.length === 0) {
                return [];
            }

            // Create a flat list that includes parent cars and child installations
            const formattedData = [];

            this.cars.forEach(car => {
                const installations = car.installations || [];
                const isPreviousCar = !car.currentInstallation;

                if (isPreviousCar) {
                    // For previous cars, create a parent row with basic car info
                    const carId = car.uuid || `car-${car.make}-${car.model}`;
                    const isExpanded = this.expandedCarIds.includes(carId);

                    // Create parent row for previous car
                    const parentRow = {
                        licenseNumber: installations[0]?.licenseNumber || '',
                        make: car.make || '',
                        model: car.model || '',
                        installedSince: '', // Empty since it's a previous car
                        deviceType: '',
                        deviceId: '',
                        information: '',

                        // Raw values for sorting
                        _licenseNumber: (installations[0]?.licenseNumber || '').toLowerCase(),
                        _make: (car.make || '').toLowerCase(),
                        _model: (car.model || '').toLowerCase(),
                        _installedSince: installations[0]?.installedAt || new Date(0),
                        _deviceType: '',

                        // Store original car data
                        originalCar: car,

                        // Set expanded flag based on our tracked array
                        _expanded: isExpanded,
                        _id: carId,
                        _hasChildren: installations.length > 0,
                        _isChild: false,
                        _level: 0,
                    };

                    formattedData.push(parentRow);

                    // If expanded, add all installations as children
                    if (isExpanded && installations.length > 0) {
                        installations.forEach((installation, index) => {
                            const childRow = {
                                licenseNumber: installation.licenseNumber || '',
                                make: '', // Empty for child rows
                                model: '', // Empty for child rows
                                installedSince: '',
                                deviceType: installation?.device?.type || '',
                                deviceId: installation?.device?.manufacturerNumber || '',
                                information: '',

                                // Raw values for sorting
                                _licenseNumber: (installation.licenseNumber || '').toLowerCase(),
                                _make: '',
                                _model: '',
                                _installedSince: installation?.installedAt || new Date(0),
                                _deviceType: (installation?.device?.type || '').toLowerCase(),

                                // Store references
                                originalCar: car,
                                installation: installation,

                                // Child row metadata
                                _id: `${carId}-installation-${index}`,
                                _parentId: carId,
                                _isChild: true,
                                _level: 1,
                                _hasChildren: false,
                            };

                            formattedData.push(childRow);
                        });
                    }
                } else {
                    // Handle current cars as before
                    const currentInstallation = car.currentInstallation;
                    const installedAt = currentInstallation?.installedAt;
                    const removedAt = currentInstallation?.removedAt;
                    const installationString = `${this.formatDate(installedAt)} ${
                        removedAt ? 'bis ' + this.formatDate(removedAt) : ''
                    }`;

                    const carId = car.uuid || `car-${car.make}-${car.model}-${currentInstallation.licenseNumber}`;
                    const isExpanded = this.expandedCarIds.includes(carId);

                    // Create the parent car row
                    const parentRow = {
                        licenseNumber: currentInstallation.licenseNumber || '',
                        make: car.make || '',
                        model: car.model || '',
                        installedSince: installationString,
                        deviceType: currentInstallation?.device?.type || '',
                        deviceId: currentInstallation?.device?.manufacturerNumber || '',
                        information: '',

                        // Raw values for sorting
                        _licenseNumber: (currentInstallation.licenseNumber || '').toLowerCase(),
                        _make: (car.make || '').toLowerCase(),
                        _model: (car.model || '').toLowerCase(),
                        _installedSince: installedAt || new Date(0),
                        _deviceType: (currentInstallation?.device?.type || '').toLowerCase(),

                        // Store original car data
                        originalCar: car,

                        // Set expanded flag based on our tracked array
                        _expanded: isExpanded,
                        _id: carId,
                        _hasChildren: installations.length > 0,
                        _isChild: false,
                        _level: 0,
                    };

                    formattedData.push(parentRow);

                    // If expanded and has installations, add child rows for each installation
                    if (isExpanded && installations.length > 0) {
                        installations.forEach((installation, index) => {
                            // Skip the current installation as it's already shown in the parent
                            if (installation === currentInstallation) return;

                            const childRow = {
                                licenseNumber: installation.licenseNumber || '',
                                make: '', // Empty for child rows
                                model: '', // Empty for child rows
                                installedSince: '',
                                deviceType: installation?.device?.type || '',
                                deviceId: installation?.device?.manufacturerNumber || '',
                                information: '',

                                // Raw values for sorting
                                _licenseNumber: (installation.licenseNumber || '').toLowerCase(),
                                _make: '',
                                _model: '',
                                _installedSince: installation?.installedAt || new Date(0),
                                _deviceType: (installation?.device?.type || '').toLowerCase(),

                                // Store references
                                originalCar: car,
                                installation: installation,

                                // Child row metadata
                                _id: `${carId}-installation-${index}`,
                                _parentId: carId,
                                _isChild: true,
                                _level: 1,
                                _hasChildren: false,
                            };

                            formattedData.push(childRow);
                        });
                    }
                }
            });

            // Apply sorting based on sortState
            if (this.sortState.column) {
                const sortKey = this.sortState.column.startsWith('_')
                    ? this.sortState.column
                    : `_${this.sortState.column}`;

                // First separate parents and children to maintain hierarchy
                const parentRows = formattedData.filter(row => !row._isChild);
                const childRowsByParent = {};

                formattedData.forEach(row => {
                    if (row._isChild && row._parentId) {
                        if (!childRowsByParent[row._parentId]) {
                            childRowsByParent[row._parentId] = [];
                        }
                        childRowsByParent[row._parentId].push(row);
                    }
                });

                // Sort parent rows
                parentRows.sort((a, b) => {
                    let valueA = a[sortKey];
                    let valueB = b[sortKey];

                    // Handle dates
                    if (valueA instanceof Date && valueB instanceof Date) {
                        return this.sortState.ascending
                            ? valueA.getTime() - valueB.getTime()
                            : valueB.getTime() - valueA.getTime();
                    }

                    // Handle strings
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        return this.sortState.ascending
                            ? valueA.localeCompare(valueB)
                            : valueB.localeCompare(valueA);
                    }

                    // Handle numbers
                    return this.sortState.ascending ? valueA - valueB : valueB - valueA;
                });

                // Sort child rows for each parent
                Object.keys(childRowsByParent).forEach(parentId => {
                    childRowsByParent[parentId].sort((a, b) => {
                        let valueA = a[sortKey];
                        let valueB = b[sortKey];

                        // Handle dates
                        if (valueA instanceof Date && valueB instanceof Date) {
                            return this.sortState.ascending
                                ? valueA.getTime() - valueB.getTime()
                                : valueB.getTime() - valueA.getTime();
                        }

                        // Handle strings
                        if (typeof valueA === 'string' && typeof valueB === 'string') {
                            return this.sortState.ascending
                                ? valueA.localeCompare(valueB)
                                : valueB.localeCompare(valueA);
                        }

                        // Handle numbers
                        return this.sortState.ascending ? valueA - valueB : valueB - valueA;
                    });
                });

                // Rebuild the sorted array with parents followed by their children
                const sortedData = [];
                parentRows.forEach(parent => {
                    sortedData.push(parent);
                    if (parent._expanded && childRowsByParent[parent._id]) {
                        sortedData.push(...childRowsByParent[parent._id]);
                    }
                });

                return sortedData;
            }

            return formattedData;
        },
    },
    methods: {
        formatDate(date) {
            return dateToString(date) || '';
        },
        hasInstallations(item) {
            const installations = item.originalCar?.installations || [];
            return installations && installations.length > 0;
        },
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleToggleExpand({ item }) {
            const carId = item._id;

            // Track expanded state
            const index = this.expandedCarIds.indexOf(carId);
            if (index >= 0) {
                // Remove if already expanded
                this.expandedCarIds.splice(index, 1);
            } else {
                // Add to expanded items
                this.expandedCarIds.push(carId);
            }
        },
        handleCarClick({ item, event }) {
            // Skip clicks from the information button and expansion controls
            if (
                (event && event.target.closest('.info-button')) ||
                (event && event.target.closest('.expand-toggle'))
            ) {
                return;
            }

            // Skip child rows
            if (item._isChild) {
                return;
            }

            // Check if we should emit history click
            if (event && event.ctrlKey) {
                this.$emit('onCarHistoryClick', { car: item.originalCar });
                return;
            }

            // For all other clicks, emit the standard car click
            this.$emit('onCarClick', { car: item.originalCar, event });
        },
        handleCarHistoryClick(item) {
            this.$emit('onCarHistoryClick', { car: item.originalCar });
        },
        handleVisibilityChange(item, isVisible) {
            this.$emit('onCarVisibilityChange', {
                car: item.originalCar,
                isVisible: isVisible,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.CarsTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    /* Make sure the BaseDashboardTable takes full available height */
    :deep(.BaseDashboardTable) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .BaseDashboardTable-container {
            flex: 1;
            min-height: 200px;

            table tbody {
                tr.child-row {
                    position: relative;
                    padding-top: 2px;
                    padding-bottom: 2px;

                    // Add vertical line for child rows
                    &::before {
                        content: '';
                        position: absolute;
                        left: 24px;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background-color: var(--color-border, #e0e0e0);
                    }

                    // Add horizontal line for child rows
                    &::after {
                        content: '';
                        position: absolute;
                        left: 24px;
                        top: 50%;
                        width: 12px;
                        height: 1px;
                        background-color: var(--color-border, #e0e0e0);
                    }

                    // Special handling for last child in the group
                    &:last-of-type::before {
                        bottom: 50%;
                    }
                }
            }
        }
    }

    .info-button {
        background: none;
        border: solid 1px rgba(0, 0, 0, 0.2);
        color: var(--color-blue);
        font-size: 0.85rem;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &:active {
            transform: scale(0.97);
        }
    }

    // Add styles for child rows and installation details
    .child-license {
        font-style: italic;
    }

    .date-range {
        .date-row {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 0.9em;

            .date-label {
                color: var(--color-gray);
                font-weight: 500;
            }

            .date-separator {
                color: var(--color-gray);
                margin: 0 4px;
            }
        }
    }

    .device-info {
        .device-row {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 0.9em;

            .device-id {
                color: var(--color-gray);
                font-size: 0.9em;
            }

            .device-separator {
                color: var(--color-gray);
                margin: 0 4px;
            }
        }
    }
}
</style>
