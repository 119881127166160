var render = function () {
  var _vm$activeCar, _vm$activeCar2, _vm$activeCar3, _vm$activeCar4, _vm$activeCar5, _vm$activeCar6, _vm$activeCar7, _vm$activeCar8, _vm$activeCar9, _vm$activeCar10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Cars",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Übersicht Ihrer",
      "highlight": "Fahrzeuge"
    }
  }), _c('h5', {
    staticClass: "Section-Title"
  }, [_vm._v("Aktuelle Zuweisungen")]), _c('cars-table', {
    attrs: {
      "cars": _vm.currentCars
    },
    on: {
      "onCarHistoryClick": _vm.handleCarHistoryClick,
      "onCarInformationClick": _vm.handleCarHistoryClick,
      "onCarVisibilityChange": _vm.handleCarVisibilityChange
    }
  }), _vm.previousCars.length > 0 ? _c('div', {
    staticClass: "Previous-Cars-Section"
  }, [_c('h5', {
    staticClass: "Section-Title"
  }, [_vm._v("Vorherige Zuweisungen")]), _c('cars-table', {
    attrs: {
      "cars": _vm.previousCars
    },
    on: {
      "onCarInformationClick": _vm.handleCarHistoryClick,
      "onCarHistoryClick": _vm.handleCarHistoryClick,
      "onCarVisibilityChange": _vm.handleCarVisibilityChange
    }
  })], 1) : _vm._e(), _c('Modal', {
    attrs: {
      "size": "medium",
      "title": _vm.activeCar ? 'Fahrzeug bearbeiten' : 'Fahrzeug hinzufügen',
      "show": _vm.modal.car.isActive,
      "isLoading": _vm.isFetchingData,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveCar
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('Section', {
    attrs: {
      "title": "Taxi Informationen"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('Input', {
    attrs: {
      "keyName": "licenseNumber",
      "label": "Kennzeichen",
      "placeholder": "Kennzeichen",
      "direction": "vertical",
      "isDisabled": true,
      "value": ((_vm$activeCar = _vm.activeCar) === null || _vm$activeCar === void 0 ? void 0 : _vm$activeCar.licenseNumber) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('DateInput', {
    attrs: {
      "keyName": "lastEichungAt",
      "value": ((_vm$activeCar2 = _vm.activeCar) === null || _vm$activeCar2 === void 0 ? void 0 : _vm$activeCar2.lastEichungAt) || '',
      "label": "Eichung zuletzt am",
      "placeholder": "dd.mm.yyyy",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('DateInput', {
    attrs: {
      "keyName": "lastTuvAt",
      "label": "TÜV zuletzt am",
      "placeholder": "dd.mm.yyyy",
      "direction": "vertical",
      "value": ((_vm$activeCar3 = _vm.activeCar) === null || _vm$activeCar3 === void 0 ? void 0 : _vm$activeCar3.lastTuvAt) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('DateInput', {
    attrs: {
      "keyName": "lastBokraftAt",
      "label": "BoKraft zuletzt am",
      "placeholder": "dd.mm.yyyy",
      "direction": "vertical",
      "value": ((_vm$activeCar4 = _vm.activeCar) === null || _vm$activeCar4 === void 0 ? void 0 : _vm$activeCar4.lastBokraftAt) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "wheels",
      "label": "KM Stand letzter Reifenwechsel",
      "placeholder": "Reifen",
      "direction": "vertical",
      "value": ((_vm$activeCar5 = _vm.activeCar) === null || _vm$activeCar5 === void 0 ? void 0 : _vm$activeCar5.wheels) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "manufacturer",
      "value": ((_vm$activeCar6 = _vm.activeCar) === null || _vm$activeCar6 === void 0 ? void 0 : _vm$activeCar6.manufacturer) || '',
      "label": "Hersteller",
      "isDisabled": true,
      "placeholder": "Hersteller",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "lastOilChange",
      "value": ((_vm$activeCar7 = _vm.activeCar) === null || _vm$activeCar7 === void 0 ? void 0 : _vm$activeCar7.lastOilChange) || '',
      "label": "KM-Stand letzter Ölwechse",
      "placeholder": "KM-Stand letzter Ölwechse",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "model",
      "label": "Modell",
      "placeholder": "Modell",
      "isDisabled": true,
      "direction": "vertical",
      "value": ((_vm$activeCar8 = _vm.activeCar) === null || _vm$activeCar8 === void 0 ? void 0 : _vm$activeCar8.model) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "vin",
      "label": "Seriennummer",
      "placeholder": "Seriennummer",
      "direction": "vertical",
      "isDisabled": true,
      "value": ((_vm$activeCar9 = _vm.activeCar) === null || _vm$activeCar9 === void 0 ? void 0 : _vm$activeCar9.vin) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "make",
      "label": "Baujahr",
      "placeholder": "Baujahr",
      "direction": "vertical",
      "isDisabled": true,
      "value": ((_vm$activeCar10 = _vm.activeCar) === null || _vm$activeCar10 === void 0 ? void 0 : _vm$activeCar10.make) || ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }