<template>
    <div class="Cars" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Übersicht Ihrer" highlight="Fahrzeuge" />

        <h5 class="Section-Title">Aktuelle Zuweisungen</h5>
        <cars-table
            :cars="currentCars"
            @onCarHistoryClick="handleCarHistoryClick"
            @onCarInformationClick="handleCarHistoryClick"
            @onCarVisibilityChange="handleCarVisibilityChange"
        />

        <div v-if="previousCars.length > 0" class="Previous-Cars-Section">
            <h5 class="Section-Title">Vorherige Zuweisungen</h5>
            <cars-table
                :cars="previousCars"
                @onCarInformationClick="handleCarHistoryClick"
                @onCarHistoryClick="handleCarHistoryClick"
                @onCarVisibilityChange="handleCarVisibilityChange"
            />
        </div>

        <Modal
            size="medium"
            :title="activeCar ? 'Fahrzeug bearbeiten' : 'Fahrzeug hinzufügen'"
            :show="modal.car.isActive"
            :isLoading="isFetchingData"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveCar }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleModalClose"
        >
            <Section title="Taxi Informationen">
                <div class="Input-Wrap">
                    <Input
                        keyName="licenseNumber"
                        label="Kennzeichen"
                        placeholder="Kennzeichen"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar?.licenseNumber || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <DateInput
                        keyName="lastEichungAt"
                        :value="activeCar?.lastEichungAt || ''"
                        label="Eichung zuletzt am"
                        placeholder="dd.mm.yyyy"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <DateInput
                        keyName="lastTuvAt"
                        label="TÜV zuletzt am"
                        placeholder="dd.mm.yyyy"
                        direction="vertical"
                        :value="activeCar?.lastTuvAt || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <DateInput
                        keyName="lastBokraftAt"
                        label="BoKraft zuletzt am"
                        placeholder="dd.mm.yyyy"
                        direction="vertical"
                        :value="activeCar?.lastBokraftAt || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="wheels"
                        label="KM Stand letzter Reifenwechsel"
                        placeholder="Reifen"
                        direction="vertical"
                        :value="activeCar?.wheels || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="manufacturer"
                        :value="activeCar?.manufacturer || ''"
                        label="Hersteller"
                        :isDisabled="true"
                        placeholder="Hersteller"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="lastOilChange"
                        :value="activeCar?.lastOilChange || ''"
                        label="KM-Stand letzter Ölwechse"
                        placeholder="KM-Stand letzter Ölwechse"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="model"
                        label="Modell"
                        placeholder="Modell"
                        :isDisabled="true"
                        direction="vertical"
                        :value="activeCar?.model || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="vin"
                        label="Seriennummer"
                        placeholder="Seriennummer"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar?.vin || ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="make"
                        label="Baujahr"
                        placeholder="Baujahr"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar?.make || ''"
                        @onKeyPress="handleKeypress"
                    />
                </div>
            </Section>
        </Modal>
    </div>
</template>

<script type="text/javascript">
import axios from 'axios';
import Fuse from 'fuse.js';
import Headline from '@/components/Headline';
import DateInput from '@/components/widgets/DateInput';
import Input from '@/components/widgets/Input';
import Modal from '@/components/widgets/Modal';
import Section from '@/components/Section';
import CarsTable from './components/CarsTable.vue';

export default {
    name: 'Cars',
    components: {
        Headline,
        Input,
        DateInput,
        Modal,
        Section,
        CarsTable,
    },
    data: () => ({
        cars: [],
        filteredCars: [],
        activeCar: null,
        isFetchingData: false,
        searchQuery: '',
        formErrors: {},
        modal: {
            delete: {
                isActive: false,
            },
            car: {
                isActive: false,
            },
        },
    }),
    computed: {
        currentCars() {
            return this.filteredCars.filter(car => car.currentInstallation);
        },
        previousCars() {
            return this.filteredCars.filter(car => !car.currentInstallation);
        },
    },
    methods: {
        handleSearch({ query }) {
            this.searchQuery = query;

            if (query.length === 0) {
                this.filteredCars = this.cars;
                return;
            }

            const options = {
                minMatchCharLength: 2,
                threshold: Number.isNaN(Number(query)) ? 0.3 : 0.2,
                keys: [
                    'installations.licenseNumber',
                    'make',
                    'model',
                    'manufacturer',
                    'currentInstallation.licenseNumber',
                ],
            };

            try {
                const fuse = new Fuse(this.cars, options);
                const result = fuse.search(query);
                this.filteredCars = result.map(r => r.item);
            } catch (error) {
                console.error('Search error:', error);
                this.$toasted.show('Fehler bei der Suche', { type: 'error' });
                this.filteredCars = this.cars;
            }
        },
        handleCarHistoryClick({ car }) {
            if (!car || !car.uuid) {
                this.$toasted.show('Fehler: Fahrzeug-ID nicht gefunden', { type: 'error' });
                return;
            }

            this.$router.push({
                path: `/cars/${car.uuid}`,
                params: {
                    id: car.uuid,
                },
            });
        },
        handleModalClose() {
            this.modal.delete.isActive = false;
            this.modal.car.isActive = false;
            this.activeCar = null;
            this.currentIndex = null;
            this.formErrors = {};
        },
        handleCarVisibilityChange({ car, isVisible }) {
            if (!car) {
                this.$toasted.show('Fehler: Kein Fahrzeug ausgewählt', { type: 'error' });
                return;
            }

            this.handleUpdateCar({ ...car, isVisible: !car.isVisible });
        },
        handleEditCar({ id, index }) {
            console.log('handleEdit', id, index);
            // Find the car by its id first, then fallback to index
            let car = null;

            if (id) {
                car = this.cars.find(c => c.id === id || c.uuid === id);
            }

            if (!car && typeof index === 'number' && index >= 0) {
                car = this.filteredCars[index];
            }

            if (!car) {
                this.$toasted.show('Fahrzeug konnte nicht gefunden werden', { type: 'error' });
                return;
            }

            this.currentIndex = index;
            this.activeCar = JSON.parse(JSON.stringify(car)); // Deep copy to prevent reference issues
            this.formErrors = {};
            this.modal.car.isActive = true;
        },
        validateCarForm() {
            const errors = {};

            // Add validation logic if needed
            // Example:
            // if (this.activeCar.lastOilChange && isNaN(this.activeCar.lastOilChange)) {
            //     errors.lastOilChange = 'KM-Stand muss eine Zahl sein';
            // }

            this.formErrors = errors;
            return Object.keys(errors).length === 0;
        },
        async handleSaveCar() {
            if (!this.validateCarForm()) {
                this.$toasted.show('Bitte korrigieren Sie die Fehler im Formular', {
                    type: 'error',
                });
                return;
            }

            try {
                await this.handleUpdateCar({ ...this.activeCar });
                await this.handleLoadCars();
                this.$toasted.show('Fahrzeug erfolgreich aktualisiert', { type: 'success' });
                this.handleModalClose();
            } catch (error) {
                console.error('Error saving car:', error);
                this.$toasted.show('Fehler beim Speichern des Fahrzeugs', { type: 'error' });
            }
        },
        handleKeypress(data) {
            this.activeCar = { ...this.activeCar, ...data };

            // Clear any form errors for the field that was just updated
            if (data && Object.keys(data)[0] && this.formErrors[Object.keys(data)[0]]) {
                const fieldName = Object.keys(data)[0];
                const updatedErrors = { ...this.formErrors };
                delete updatedErrors[fieldName];
                this.formErrors = updatedErrors;
            }
        },
        async handleUpdateCar(car) {
            if (!car || !car.uuid) {
                this.$toasted.show('Fehler: Fahrzeug-ID nicht gefunden', { type: 'error' });
                return;
            }

            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars/${car.uuid}`;
                await axios.put(url, car, {
                    withCredentials: true,
                });
                return true;
            } catch (error) {
                console.error('Error updating car:', error);

                // Handle API error responses
                if (error.response && error.response.data) {
                    const errorMessage =
                        error.response.data.message ||
                        'Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut';
                    this.$toasted.show(errorMessage, { type: 'error' });
                } else {
                    this.$toasted.show(
                        'Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut',
                        {
                            type: 'error',
                        },
                    );
                }
                return false;
            } finally {
                this.isFetchingData = false;
            }
        },
        async handleLoadCars() {
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.cars = result.data || [];

                // Re-apply any active search filter
                if (this.searchQuery) {
                    this.handleSearch({ query: this.searchQuery });
                } else {
                    this.filteredCars = this.cars;
                }
            } catch (error) {
                console.error('Error loading cars:', error);
                this.$toasted.show('Ein Fehler ist beim Laden der Fahrzeuge aufgetreten.', {
                    type: 'error',
                });
                this.cars = [];
                this.filteredCars = [];
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        this.handleLoadCars();
    },
};
</script>

<style lang="scss">
.Cars {
    @extend %contentWrapper;
    @extend %page;

    .Input-Wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 30px;
        margin-top: 40px;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            grid-template-columns: 1fr;
        }
    }

    .Search {
        border: solid 1px var(--color-text-inactive);
        border-radius: 4px;
        margin: 5px 10px 20px 0;
    }

    .Section-Title {
        margin-bottom: 15px;
        font-weight: 600;
    }

    .Previous-Cars-Section {
        margin-top: 30px;
    }
}
</style>
