var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CarsTable"
  }, [_c('BaseDashboardTable', {
    ref: "dashboardTable",
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedCars,
      "sort": _vm.sortState,
      "empty-message": 'Keine Zuweisungen vorhanden',
      "use-hierarchical-rows": true
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-click": _vm.handleCarClick,
      "toggle-expand": _vm.handleToggleExpand,
      "row-hover": function rowHover(args) {
        return _vm.$emit('onRowHover', args);
      },
      "row-leave": function rowLeave(args) {
        return _vm.$emit('onRowLeave', args);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrzeuge")])];
      },
      proxy: true
    }, {
      key: "column-licenseNumber",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          class: {
            'child-license': item._isChild
          }
        }, [_vm._v(_vm._s(item.licenseNumber))])];
      }
    }, {
      key: "column-installedSince",
      fn: function fn(_ref2) {
        var _item$installation, _item$installation2;
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "date-range"
        }, [item._isChild ? _c('div', {
          staticClass: "date-row"
        }, [_c('span', {
          staticClass: "date-label"
        }, [_vm._v("Von: ")]), _c('span', [_vm._v(_vm._s(_vm.formatDate((_item$installation = item.installation) === null || _item$installation === void 0 ? void 0 : _item$installation.installedAt)))]), _c('span', {
          staticClass: "date-separator"
        }, [_vm._v("|")]), _c('span', {
          staticClass: "date-label"
        }, [_vm._v("Bis: ")]), _c('span', [_vm._v(_vm._s(_vm.formatDate((_item$installation2 = item.installation) === null || _item$installation2 === void 0 ? void 0 : _item$installation2.removedAt)))])]) : _c('span', [_vm._v(_vm._s(item.installedSince))])])];
      }
    }, {
      key: "column-deviceType",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "device-info"
        }, [item._isChild ? _c('div', {
          staticClass: "device-row"
        }, [_c('span', [_vm._v(_vm._s(item.deviceType))]), item.deviceId ? _c('span', {
          staticClass: "device-separator"
        }, [_vm._v("|")]) : _vm._e(), item.deviceId ? _c('span', {
          staticClass: "device-id"
        }, [_vm._v("ID: " + _vm._s(item.deviceId))]) : _vm._e()]) : _c('span', [_vm._v(_vm._s(item.deviceType))])])];
      }
    }, {
      key: "column-information",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [!item._isChild ? _c('Button', {
          attrs: {
            "variant": "table",
            "size": "small"
          },
          on: {
            "onClick": function onClick($event) {
              return _vm.$emit('onCarInformationClick', {
                car: item.originalCar
              });
            }
          }
        }, [_vm._v(" Informationen ")]) : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }