<template>
    <div
        class="DateInput"
        :class="[
            { 'is-disabled': isDisabled },
            { 'has-error': error && error.length > 0 },
            `is-${direction}`,
        ]"
        :style="style"
    >
        <label class="Label" :for="label">{{ label }}</label>
        <input
            class="Input"
            ref="input"
            v-model="displayValue"
            type="text"
            :name="label"
            :disabled="isDisabled"
            :placeholder="formatPlaceholder"
            @blur="formatInput"
            @keyup.enter="$emit('onEnterPress')"
            :maxlength="maxInputLength"
        />
        <span v-if="error && error.length > 0" class="Error">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: 'DateInput',
    props: {
        label: {
            type: String,
            default: 'Date of Birth',
        },
        keyName: {
            type: String,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        direction: {
            type: String,
            default: 'vertical',
        },
        marginBottom: {
            type: Number,
            default: 0,
        },
        marginTop: {
            type: Number,
            default: 0,
        },
        format: {
            type: String,
            default: 'dd.mm.yyyy', // German format by default
            validator: value => ['dd.mm.yyyy', 'mm/dd/yyyy', 'yyyy-mm-dd'].includes(value),
        },
        minYear: {
            type: Number,
            default: 1900,
        },
        maxYear: {
            type: Number,
            default: 2099,
        },
    },

    data() {
        return {
            displayValue: '',
            isFormatting: false,
            typingTimer: null,
        };
    },

    computed: {
        style() {
            return {
                marginBottom: `${this.marginBottom * 5}px`,
                marginTop: `${this.marginTop * 5}px`,
            };
        },

        formatPlaceholder() {
            return this.placeholder || this.format;
        },

        maxInputLength() {
            // Maximum length based on format (including separators)
            return this.format.length;
        },

        formatSeparator() {
            if (this.format.includes('.')) return '.';
            if (this.format.includes('/')) return '/';
            if (this.format.includes('-')) return '-';
            return '.'; // Default to German separator
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                if (!this.isFormatting && newValue !== this.outputValue) {
                    this.displayValue = this.formatDateForDisplay(newValue);
                }
            },
        },

        displayValue(newValue) {
            if (this.isFormatting) return;

            // Clear previous timer
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }

            // Set a new timer to wait for user to finish typing
            this.typingTimer = setTimeout(() => {
                // Only process after the user has stopped typing for 800ms
                this.processInput(newValue);
            }, 800);
        },
    },

    methods: {
        processInput(value) {
            // Strip everything except numbers and common separators
            const cleanValue = value.replace(/[^\d./-]/g, '');

            if (!cleanValue) {
                this.emitChange('');
                return;
            }

            // Try to detect different formats being pasted in
            if (this.containsFullDate(cleanValue)) {
                this.handlePastedDate(cleanValue);
            }
        },

        containsFullDate(value) {
            // Check if the value contains a full date (has 2 separators)
            const dotCount = (value.match(/\./g) || []).length;
            const dashCount = (value.match(/-/g) || []).length;
            const slashCount = (value.match(/\//g) || []).length;

            return dotCount >= 2 || dashCount >= 2 || slashCount >= 2;
        },

        handlePastedDate(value) {
            let day, month, year;
            let dateParts = [];

            // Detect separator used in pasted value
            let separator = '.';
            if (value.includes('-')) separator = '-';
            if (value.includes('/')) separator = '/';

            dateParts = value.split(separator);

            // Handle different formats intelligently
            if (dateParts.length === 3) {
                // Check if year is first (YYYY-MM-DD)
                if (dateParts[0].length === 4) {
                    year = dateParts[0];
                    month = dateParts[1];
                    day = dateParts[2];
                }
                // Check if year is last (DD.MM.YYYY or MM/DD/YYYY)
                else if (dateParts[2].length === 4) {
                    year = dateParts[2];

                    // Determine if this is DD.MM.YYYY or MM/DD/YYYY
                    if (separator === '.' || this.format.startsWith('dd')) {
                        // German format: DD.MM.YYYY
                        day = dateParts[0];
                        month = dateParts[1];
                    } else {
                        // US format: MM/DD/YYYY
                        month = dateParts[0];
                        day = dateParts[1];
                    }
                }
                // Handle 2-digit years
                else {
                    // Assume last part is year for 2-digit year formats
                    if (separator === '.' || this.format.startsWith('dd')) {
                        // German format: DD.MM.YY
                        day = dateParts[0];
                        month = dateParts[1];
                        year = '20' + dateParts[2]; // Assume 20xx for 2-digit years
                    } else {
                        // US format: MM/DD/YY
                        month = dateParts[0];
                        day = dateParts[1];
                        year = '20' + dateParts[2]; // Assume 20xx for 2-digit years
                    }
                }

                // Validate date parts
                day = this.padZero(parseInt(day, 10));
                month = this.padZero(parseInt(month, 10));

                // Basic validation
                if (this.isValidDate(day, month, year)) {
                    this.isFormatting = true;
                    this.displayValue = this.formatDateParts(day, month, year);
                    this.emitChange(this.getISODate(day, month, year));
                    this.$nextTick(() => {
                        this.isFormatting = false;
                    });
                }
            }
        },

        formatInput() {
            // Format on blur to ensure correct display
            const value = this.displayValue.trim();

            if (!value) {
                this.emitChange('');
                return;
            }

            // Extract numbers
            const numbers = value.replace(/\D/g, '');

            if (numbers.length >= 4) {
                let day, month, year;

                // Based on the format and input, try to parse date parts
                if (this.format.startsWith('dd')) {
                    // German format DD.MM.YYYY
                    day = numbers.substring(0, 2);
                    month = numbers.substring(2, 4);
                    year = numbers.substring(4, 8);
                } else if (this.format.startsWith('mm')) {
                    // US format MM/DD/YYYY
                    month = numbers.substring(0, 2);
                    day = numbers.substring(2, 4);
                    year = numbers.substring(4, 8);
                } else {
                    // ISO format YYYY-MM-DD
                    year = numbers.substring(0, 4);
                    month = numbers.substring(4, 6);
                    day = numbers.substring(6, 8);
                }

                // Handle 2-digit year
                if (year && year.length === 2) {
                    year = '20' + year; // Assume 20xx for 2-digit years
                }

                // Validate parts
                if (this.isValidDate(day, month, year)) {
                    this.isFormatting = true;
                    this.displayValue = this.formatDateParts(day, month, year);
                    this.emitChange(this.getISODate(day, month, year));
                    this.$nextTick(() => {
                        this.isFormatting = false;
                    });
                }
            }
        },

        formatDateParts(day, month, year) {
            // Format according to the specified date format
            if (this.format === 'dd.mm.yyyy') {
                return `${day}.${month}.${year}`;
            } else if (this.format === 'mm/dd/yyyy') {
                return `${month}/${day}/${year}`;
            } else if (this.format === 'yyyy-mm-dd') {
                return `${year}-${month}-${day}`;
            }

            // Default to German format
            return `${day}.${month}.${year}`;
        },

        formatDateForDisplay(isoDate) {
            if (!isoDate) return '';

            // Parse ISO date (YYYY-MM-DD)
            const parts = isoDate.split('-');
            if (parts.length !== 3) return isoDate; // Return as is if not in expected format

            const year = parts[0];
            const month = parts[1];
            const day = parts[2].substring(0, 2); // Remove any time part

            return this.formatDateParts(day, month, year);
        },

        getISODate(day, month, year) {
            // Format as ISO date (YYYY-MM-DD) for output
            return new Date(year, month - 1, day).toISOString();
        },

        isValidDate(day, month, year) {
            // Basic validation
            const dayNum = parseInt(day, 10);
            const monthNum = parseInt(month, 10);
            const yearNum = parseInt(year, 10);

            if (isNaN(dayNum) || isNaN(monthNum) || isNaN(yearNum)) {
                return false;
            }

            // Month validation
            if (monthNum < 1 || monthNum > 12) {
                return false;
            }

            // Year validation
            if (yearNum < this.minYear || yearNum > this.maxYear) {
                return false;
            }

            // Day validation based on month
            const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
            if (dayNum < 1 || dayNum > daysInMonth) {
                return false;
            }

            return true;
        },

        padZero(num) {
            // Ensure numbers are two digits with leading zeros
            return num < 10 ? '0' + num : '' + num;
        },

        emitChange(value) {
            // Use the same emit format as the original Input component
            // to maintain compatibility
            const givenKeyName = this.keyName || this.label;
            const isCamelCase = (givenKeyName || '').match(/[a-z]+/g)?.length > 1;
            let key = isCamelCase ? givenKeyName : givenKeyName.toLowerCase();
            key = key.replace(/ ([a-z])/g, (m, w) => w.toUpperCase());

            this.$emit('onKeyPress', { [key]: value });
        },
    },
};
</script>

<style lang="scss">
.DateInput {
    position: relative;
    width: 100%;

    &.has-error {
        .Input,
        label {
            border-color: var(--color-red) !important;
            color: var(--color-red) !important;
        }
    }

    &.is-vertical {
        .Label {
            position: relative;
            top: 0px;
            left: 10px;
            transition: all 0.2s ease-out;
            font-size: 0.875rem;
            transform: translateY(-35px);
        }

        .Input {
            padding: 12px 30px 12px 12px;

            &::placeholder {
                color: var(--color-text-gray-dark);
            }

            &:focus,
            &:valid {
                + .Label {
                    transform: translateY(-30px);
                    font-size: 0.75rem;
                }
            }
        }
    }

    &.is-horizontal {
        display: flex;
        align-items: center;

        .Label {
            transition: all 0.2s ease-out;
            font-size: 1rem;
            flex: unset;
            width: auto;
            min-width: 30%;

            &::after {
                content: ':';
            }
        }

        .Input {
            flex: 1;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;

            &.is-disabled {
                .Input {
                    padding-left: 0;
                }
            }
            .Input {
                width: 100%;
            }
        }
    }

    &.is-disabled {
        .Input {
            color: var(--color-text-gray-dark);
            background-color: transparent;
        }
    }

    .Label,
    .Input {
        flex: 1;
    }

    .Label {
        color: var(--color-text-black);
    }

    .Input {
        outline: none;
        margin: 0;
        border: none;
        font-family: inherit;
        width: 100%;
        padding: 12px 30px 12px 22px;
        font-size: 1rem;
        background-color: var(--color-bg);
        color: var(--color-text-black);
        border-radius: 5px;

        &::placeholder {
            color: var(--color-text-gray-dark);
        }

        &:focus {
            outline: solid 1px var(--color-blue-dark);

            + .Label {
                transform: translateY(-30px);
                font-size: 0.75rem;
            }
        }
    }

    .Error {
        color: var(--color-red);
        margin-top: 5px;
        font-size: 12px;
        position: absolute;
        bottom: -23px;
        left: 12px;
    }
}
</style>
