var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DateInput",
    class: [{
      'is-disabled': _vm.isDisabled
    }, {
      'has-error': _vm.error && _vm.error.length > 0
    }, "is-".concat(_vm.direction)],
    style: _vm.style
  }, [_c('label', {
    staticClass: "Label",
    attrs: {
      "for": _vm.label
    }
  }, [_vm._v(_vm._s(_vm.label))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayValue,
      expression: "displayValue"
    }],
    ref: "input",
    staticClass: "Input",
    attrs: {
      "type": "text",
      "name": _vm.label,
      "disabled": _vm.isDisabled,
      "placeholder": _vm.formatPlaceholder,
      "maxlength": _vm.maxInputLength
    },
    domProps: {
      "value": _vm.displayValue
    },
    on: {
      "blur": _vm.formatInput,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('onEnterPress');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.displayValue = $event.target.value;
      }
    }
  }), _vm.error && _vm.error.length > 0 ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }